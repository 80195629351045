@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
  }
}


.footer-backgroud-color {
  background-color: var(--footer-backgroud);
}

.img-icon > path {
  fill: aqua;
}

.required:after {
  content: " *";
  color: red;
}

body {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.bg-light-primary {
  background-color: hsl(var(--light-primary));
  color: hsl(var(--primary));
}

.light-primary-btn {
  background-color: hsl(var(--light-primary));
  color: hsl(var(--primary));
}

.light-primary-text {
  color: hsl(var(--light-primary));
}

/* input::-ms-reveal,
input::-ms-clear {
  display: none;
} */
input:active input::-ms-reveal {
  display: block;
}
.c-table table thead th {
  border: 1px solid hsl(var(--primary));
  border-collapse: collapse;
  padding: 0.75rem;
}

.c-table table thead tr {
  background: hsl(var(--primary));
  color: white;
  font-size: smaller;
}

.c-table table tbody td {
  border: 1px solid hsl(var(--primary));
  border-collapse: collapse;
  font-size: smaller;
  padding: 1rem;
}

.loginHeading {
  font-size: 24px;
  font-weight: 700;
  line-height: 29.26px;
  color: #42425e;
}
@layer utilities {
  .w-485 {
    width: 48%;
  }
}
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield; /* Hides the spinner */
  }
}


.CircularProgressbar-trail {
  transformOrigin: 'center center',
}


@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

input[type="time"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-calendar-picker-indicator {
  display: none;
}

/* Hide the spinner buttons for time input in browsers like Chrome */
input[type="time"]::-webkit-outer-spin-button,
input[type="time"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-outer-spin-button,
input[type="month"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Apply appearance settings for both time and month inputs in Firefox and other browsers */
input[type="time"],
input[type="month"] {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield; /* Other browsers */
}

.graph-wrapper{
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}